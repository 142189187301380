export const menu = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'About',
    path: '/about',
  },
  {
    title: 'Services',
    path: '/services',
  },
  {
    title: 'News',
    path: '/news',
  },
  {
    title: 'Testimonials',
    path: '/#testimonials',
  },
  {
    title: 'Contact',
    path: '/#contact',
  },
];
