import React, { useContext, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { graphql, useStaticQuery } from 'gatsby';
import axios from 'axios';
import { SearchModalStyles } from '../../styles/search/SearchModalStyles';
import ActionButton from '../buttons/ActionButton';
import { SearchModalContext } from '../../context/searchModalContext';
import SearchField from './SearchField';
import SearchResult from './SearchResult';

const query = graphql`
  {
    localSearchProjects {
      publicIndexURL
      publicStoreURL
    }
    localSearchAuthors {
      publicIndexURL
      publicStoreURL
    }
    localSearchServices {
      publicIndexURL
      publicStoreURL
    }
    localSearchEvent {
      publicIndexURL
      publicStoreURL
    }
  }
`;

function Search() {
  const { isSearchModalOpen, closeSearchModal } =
    useContext(SearchModalContext);
  const [searchQuery, setSearchQuery] = useState('');
  const data = useStaticQuery(query);
  const [projectsIndexStore, setProjectsIndexStore] = useState(null);
  const [authorsIndexStore, setAuthorsIndexStore] = useState(null);
  const [servicesIndexStore, setServicesIndexStore] = useState(null);
  const [eventIndexStore, setEventIndexStore] = useState(null);

  useEffect(() => {
    if (isSearchModalOpen) {
      document.body.style.overflow = 'hidden';
      setSearchQuery('');
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [isSearchModalOpen]);

  const {
    publicStoreURL: projectsPublicStoreUrl,
    publicIndexURL: projectsPublicIndexUrl,
  } = data.localSearchProjects;

  const {
    publicStoreURL: authorsPublicStoreUrl,
    publicIndexURL: authorsPublicIndexUrl,
  } = data.localSearchAuthors;
  const {
    publicStoreURL: servicesPublicStoreUrl,
    publicIndexURL: servicesPublicIndexUrl,
  } = data.localSearchServices;
  const {
    publicStoreURL: eventPublicStoreUrl,
    publicIndexURL: eventPublicIndexUrl,
  } = data.localSearchEvent;

  const handleOnFocus = async () => {
    if (
      projectsIndexStore &&
      authorsIndexStore &&
      servicesIndexStore &&
      eventIndexStore
    )
      return;

    const [
      { data: projectsIndex },
      { data: projectsStore },
      { data: authorsIndex },
      { data: authorsStore },
      { data: servicesIndex },
      { data: servicesStore },
      { data: eventIndex },
      { data: eventStore },
    ] = await Promise.all([
      axios.get(projectsPublicIndexUrl),
      axios.get(projectsPublicStoreUrl),
      axios.get(authorsPublicIndexUrl),
      axios.get(authorsPublicStoreUrl),
      axios.get(servicesPublicIndexUrl),
      axios.get(servicesPublicStoreUrl),
      axios.get(eventPublicIndexUrl),
      axios.get(eventPublicStoreUrl),
    ]);
    setProjectsIndexStore({
      index: projectsIndex,
      store: projectsStore,
    });

    setAuthorsIndexStore({
      index: authorsIndex,
      store: authorsStore,
    });
    setServicesIndexStore({
      index: servicesIndex,
      store: servicesStore,
    });
    setEventIndexStore({
      index: eventIndex,
      store: eventStore,
    });
  };
  if (!isSearchModalOpen) return null;
  return (
    <SearchModalStyles>
      <div className="modal__container">
        <ActionButton onClick={() => closeSearchModal()} className="close">
          <MdClose />
        </ActionButton>
        <SearchField
          value={searchQuery}
          setValue={setSearchQuery}
          onFocus={handleOnFocus}
        />
        {searchQuery &&
          projectsIndexStore &&
          authorsIndexStore &&
          servicesIndexStore &&
          eventIndexStore && (
            <div className="search__result">
              <SearchResult
                searchQuery={searchQuery}
                projectsIndexStore={projectsIndexStore}
                authorsIndexStore={authorsIndexStore}
                servicesIndexStore={servicesIndexStore}
                eventIndexStore={eventIndexStore}
              />
            </div>
          )}
      </div>
    </SearchModalStyles>
  );
}

export default Search;
