import styled from 'styled-components';

export const FooterStyles = styled.footer`
  width: 100%;
  background-color: var(--perfect-sanctuary-blue);
  display: flex;
  align-items: color-interpolation-filters;
  justify-content: center;
  background-size: cover;
  background-position: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    padding-block-end: 0;

    .footer-top {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-block-end: 4rem;

      @media (max-width: 500px) {
        .logo {
          width: 200px !important;
        }
      }

      & > .tanya-footer {
        margin-block-end: 6rem;
        object-position: center !important;
        height: 600px;

        img {
          object-position: center !important;
        }
      }

      .footer-top-right {
        width: 400px;
        form {
          display: flex;
          align-items: stretch;
          width: 100%;

          input {
            padding: 0.75rem;
            margin-inline-end: -2rem;
            border-radius: 2rem 0rem 0rem 2rem;
            border: 1px solid white;
            width: 100%;
          }
        }
      }
      .footer-contact-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;

        .contact-full {
          width: 100% !important;
          max-width: 900px;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        & > h3 {
          text-align: center !important;
          margin-block: 0.5rem 2rem;
        }
        p {
          margin: 0;
          margin-block: 1rem 2rem;
          max-width: 32ch;
          text-align: left;
          text-align: center;
        }
        .logo {
          width: 18rem;
          margin-block: 2rem 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        #tel {
          margin-block: 0.5rem 1rem;
          color: white;
        }
      }
    }
    .footer-middle {
      margin-block: 2rem;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
      width: 100%;
    }

    p {
      text-align: center;
      max-width: 50ch;
      color: var(--perfect-sanctuary-light-blue);
      margin-block-start: 2rem;
    }
    ul {
      gap: 2rem;

      & > ul {
        gap: 1rem;
      }

      li {
        a {
          color: white;
        }
      }

      &:nth-of-type(2) {
        margin-block-end: 2rem;
      }
    }
  }
  .footer-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid var(--perfect-sanctuary-light-blue);
    padding-block: 2rem;

    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: white;
      gap: 0.5rem;

      svg {
        width: 8rem;
      }
    }
  }
  @media (max-width: 600px) {
    .footer-top {
      .footer-top-right {
        width: 100% !important;
      }
    }
    .footer-middle {
      .logo {
        width: 14rem !important;
      }
      ul {
        flex-direction: column;
        align-items: center !important;

        ul {
          li {
            text-align: center;
          }
        }
      }
    }
    .footer-bottom {
      flex-direction: column;
      align-items: center;

      a {
        align-items: center;
      }
    }
  }
`;
