import React from 'react';
import { getYear } from 'date-fns';
import { StaticImage } from 'gatsby-plugin-image';
import { FooterStyles } from '../styles/FooterStyles';
import FooterUlContainer from './footer/FooterUlContainer';
import IslandWebDesignLogo from '../../svg/island-cream-logo.svg';

function Footer({ data }) {
  return (
    <FooterStyles>
      <div className="container">
        <div className="footer-top">
          <StaticImage
            alt="Perfect Sanctuary Tanya Hames"
            placeholder="blurred"
            className="tanya-footer"
            src="../../static/family_a922258b-a59d-4b5f-82b7-278534167e2b_036.JPG"
          />

          {/* Footer Contact Section End */}
          {/* <h3>Subscribe to our newsletter</h3>

          <div className="footer-top-right">
            <form action="POST">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="email..."
              />
              <Button variant="secondary">Subscribe</Button>
            </form>
          </div> */}
        </div>

        <div className="footer-middle">
          <FooterUlContainer />
        </div>
        <div className="footer-bottom">
          <a
            rel="external author noreferrer"
            target="_blank"
            href="https://islandwebdesign.net/"
          >
            Designed by...
            <IslandWebDesignLogo />
          </a>
          <p>{`${getYear(
            new Date()
          )} Perfect Sanctuary Org All Rights Reserved`}</p>
        </div>
      </div>
    </FooterStyles>
  );
}
export default Footer;
