import React from 'react';
import { ParagraphTextStyles } from '../../styles/typography/ParagraphTextStyles';

function ParagrapghText({ children, ref, ...props }) {
  return (
    <ParagraphTextStyles {...props} ref={ref}>
      {children}
    </ParagraphTextStyles>
  );
}

export default ParagrapghText;
