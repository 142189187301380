exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-project-list-js": () => import("./../../../src/templates/project-list.js" /* webpackChunkName: "component---src-templates-project-list-js" */),
  "component---src-templates-service-list-js": () => import("./../../../src/templates/service-list.js" /* webpackChunkName: "component---src-templates-service-list-js" */),
  "component---src-templates-single-author-jsx": () => import("./../../../src/templates/single-author.jsx" /* webpackChunkName: "component---src-templates-single-author-jsx" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/single-event.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-project-js": () => import("./../../../src/templates/single-project.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-templates-single-service-js": () => import("./../../../src/templates/single-service.js" /* webpackChunkName: "component---src-templates-single-service-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

