import styled from 'styled-components';

export default styled.header`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  padding: 0rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .loop {
    display: none !important;
  }

  .logo {
    @media (max-width: 1024px) {
      .LogoStyles-hxkVnj.eOfOqx:first-child {
        /* display: none; */
      }
    }
    @media (max-width: 450px) {
      /* display: flex; */
      align-items: center;
      justify-content: flex-start !important;
    }
    .static-wrapper {
      padding: 0.5rem;
      background-color: white;
      border-radius: 2rem;
    }

    .ifsg-badge {
      width: 100px;

      @media (max-width: 450px) {
        width: 75px;
      }
    }
  }

  .container {
    padding-block: 1rem;
  }
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav__wrapper {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      li {
        color: white;
        text-align: left !important;

        a {
          text-align: left !important;

          color: var(--perfect-sanctuary-blue);
          text-decoration: none;
          font-size: var(--text);
          padding: 0.5rem 1rem;
          border-radius: 0.25rem;
          transition: background-color 500ms ease;
        }
        &:hover {
          transition: background-color 300ms ease;

          a {
            background: var(--perfect-sanctuary-light-blue);
            color: var(--perfect-sanctuary-blue) !important;
            transition: background-color 500ms ease;
          }
        }
      }
    }
  }

  .searchIcon {
    cursor: pointer;
    font-size: 2.25rem;
    color: var(--perfect-sanctuary-blue) !important;

    @media (max-width: 345px) {
      font-size: 2rem !important;
    }
    .searchIcon__wrapper {
      border-radius: 0.25rem;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: var(--perfect-sanctuary-light-blue) !important;
    }
  }
  .mobileMenuCloseBtn,
  .mobileNavBg,
  .mobileIcon,
  .mobileMenuBtn {
    display: none;
  }

  @media (max-width: 1256px) {
    .logo-loop {
      width: 200px !important;

      @media (max-width: 360px) {
        width: 150px !important;
      }
    }

    .static-wrapper {
      display: none !important;
    }
    .mobileMenuCloseBtn,
    .mobileNavBg,
    .mobileIcon,
    .mobileMenuBtn {
      display: initial;
    }
    .nav__wrapper {
      nav {
        z-index: 1002;
        position: fixed;
        top: 0;
        right: 0;
        width: 80%;
        transform: translateX(100%);
        background-color: #ffffff20;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease transform;
        ul {
          width: 90%;
          margin: 0 auto;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;

          li {
            display: block;
            margin: 0.5rem 0;

            a {
              padding: 0 !important;
            }

            &:hover {
              a {
                background: var(--perfect-sanctuary-light-blue);
              }
            }

            a {
              text-align: left !important;

              width: 100%;
              padding: 0.5rem 1rem;
              border-radius: 0.25rem;
              font-size: var(--h3);
              color: white !important;
            }
            &.searchIcon {
              display: none;
            }
          }
        }
        .mobileMenuCloseBtn {
          color: white;
          position: absolute;
          top: 10px;
          right: 10px;
          border-color: none;
          border: none;
          border-radius: 50%;

          svg {
            font-size: 3rem;
          }
          &:hover {
            color: crimson;
          }
        }
      }
      &.open {
        nav {
          transform: translateX(0);
        }
      }
      .mobileNavBg {
        z-index: 1001;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        transition: opacity 0.6s linear 0s;
        background: rgba(0, 0, 0, 0.5);
      }
    }
    .mobileIcon {
      display: flex;
      gap: 1rem;

      @media (max-width: 360px) {
        gap: 0;
      }

      align-items: center;
    }
    .mobileMenuBtn {
      border: none;

      svg {
        font-size: 2rem;
        color: var(--perfect-sanctuary-blue) !important;

        @media (max-width: 345px) {
          font-size: 1.75rem !important;
        }
      }
      &:hover {
        svg {
          color: var(--perfect-sanctuary-light-blue) !important;
        }
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    gap: 2rem;

    @media (max-width: 360px) {
      gap: 1rem;
    }
    a {
      display: flex;
      align-items: center;
    }
    .logo-loop {
      padding: 0;
      margin: 0;
      height: auto;
      width: 18rem;
      max-width: auto;
    }

    .badge {
      width: 4rem;
      height: auto;
    }
  }
`;
