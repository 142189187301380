import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { FooterUlStyles } from '../../styles/footer/FooterUlStyles';

function FooterUlContainer() {
  const footerData = useStaticQuery(graphql`
    query FooterQuery {
      allSanityService {
        nodes {
          name
          slug {
            current
          }
        }
      }
      allSanityProject {
        nodes {
          name: title
          slug {
            current
          }
        }
      }
      allSanityCategory {
        nodes {
          name: title
          slug {
            current
          }
        }
      }
      allSanityBio {
        nodes {
          name

          slug {
            current
          }
        }
      }
    }
  `);

  const allHome = {
    name: 'Home',
    listItems: [],
  };

  const allAuthors = {
    name: 'About',
    listItems: [],
  };

  const allServices = {
    name: 'Services',
    listItems: footerData.allSanityService.nodes,
  };

  const allProjects = {
    name: 'News',
    listItems: footerData.allSanityProject.nodes,
  };

  const allTestimonials = {
    name: 'Testimonials',
    listItems: [],
  };

  const allContact = {
    name: 'Contact',
    listItems: [],
  };

  const allFooterData = [
    allHome,
    allAuthors,
    allServices,
    allProjects,
    allTestimonials,
    allContact,
  ];

  return (
    <FooterUlStyles>
      {allFooterData.map((singleFooterData, index) => (
        <ul>
          <Link
            className="main-link"
            to={
              singleFooterData.name === 'Home'
                ? `/`
                : singleFooterData.name === 'Testimonials' ||
                  singleFooterData.name === 'Contact'
                ? `/#${singleFooterData.name.toLowerCase()}`
                : `/${singleFooterData.name.toLowerCase()}`
            }
          >
            <li>{singleFooterData.name}</li>
          </Link>
          {singleFooterData.listItems.length > 0
            ? singleFooterData.listItems.map((item, index) => (
                <Link
                  to={`/${singleFooterData.name.toLowerCase()}/${
                    item.slug.current
                  }`}
                >
                  <li>{item.name}</li>
                </Link>
              ))
            : ''}
        </ul>
      ))}
    </FooterUlStyles>
  );
}

export default FooterUlContainer;
