import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import format from 'date-fns/format';
import { SearchResultItemStyles } from '../../styles/search/SearchResultItemStyles';
import { Title } from '../typography/Title';
import ParagrapghText from '../typography/ParagrapghText';
import { SearchModalContext } from '../../context/searchModalContext';

function ProjectsSearchResultItem({ project }) {
  const { closeSearchModal } = useContext(SearchModalContext);
  return (
    <SearchResultItemStyles
      onClick={closeSearchModal}
      to={`/news/${project.slug.current}`}
    >
      <GatsbyImage
        image={project.coverImage.asset.gatsbyImageData}
        className="img"
        alt={project.coverImage.alt}
      />
      <div>
        <Title tag="h4" className="title">
          {project.title}
        </Title>
        <ParagrapghText className="publishedAtText">
          {format(new Date(project.publishedAt), 'p | MMM dd yyyy')}
        </ParagrapghText>
      </div>
    </SearchResultItemStyles>
  );
}

function AuthorsSearchResultItem({ author }) {
  const { closeSearchModal } = useContext(SearchModalContext);

  return (
    <SearchResultItemStyles onClick={closeSearchModal} to="/about">
      <GatsbyImage
        image={author.profileImage.asset.gatsbyImageData}
        className="authorProfileImg"
        alt={author.profileImage.alt}
      />
      <Title tag="h4" className="title">
        {author.name}
      </Title>
    </SearchResultItemStyles>
  );
}
function ServicesSearchResultItem({ services }) {
  const { closeSearchModal } = useContext(SearchModalContext);

  return (
    <SearchResultItemStyles onClick={closeSearchModal} to="/services">
      <GatsbyImage
        image={services.displayImage.asset.gatsbyImageData}
        className="img"
        alt={services.displayImage.alt}
      />
      <Title tag="h4" className="title">
        {services.name}
      </Title>
    </SearchResultItemStyles>
  );
}

export {
  ProjectsSearchResultItem,
  AuthorsSearchResultItem,
  ServicesSearchResultItem,
};
