import { Link } from 'gatsby';
import styled from 'styled-components';

export const SearchResultItemStyles = styled(Link)`
  width: 100%;
  background-color: var(--perfect-sanctuary-light-blue);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  border-radius: 0.25rem;
  .img {
    width: 50px;
    height: 40px;
    border-radius: 4px;
  }

  .title {
    font-size: var(--mobile-h3);
    color: var(--perfect-sanctuary-blue);
  }
  .publishedAtText {
    font-size: var(--text);
    a {
      color: var(--grey);
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .authorProfileImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;
