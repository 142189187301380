import styled from 'styled-components';

export const FooterUlStyles = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
  width: 100%;

  & > li {
    font-weight: 700;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    width: 100%;
    max-width: 150px;
    a {
      color: white;

      li {
        text-align: left;
        font-size: var(--text);
      }
      &:nth-child(1) {
        font-weight: 700;
      }
    }
  }
`;
