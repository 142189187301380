import React from 'react';
import PerfectSanctuaryLogo from '../../svg/tanya-perfect-sanctuary-side-logo.svg';
import LogoStyles from '../styles/LogoStyles';

function Logo({ ...props }) {
  return (
    <LogoStyles to="/">
      <PerfectSanctuaryLogo {...props} />
    </LogoStyles>
  );
}

export default Logo;
