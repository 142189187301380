// Fonts
/* eslint-disable */
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/300.css';
import React from 'react';
import Layout from './src/components/Layout';
/* eslint-enable */

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

export const wrapPageElement = ({ element, ...props }) => (
  <Layout {...props}>{element}</Layout>
);
