import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .page {
    border:20px solid blue;
  }

  
  :root{
    --primary: #4A46EB;
    --secondary: #753AFF;
    --darkBlue: #070747; 
    --darkPurple: #0E0034;
    --black-1: #0D121F;
    --black-2: #121826;
    --white-1: #C6BED9;
    --gray: #64718A;
    --grey: var(--gray);
    --white: white;
    --black: black;

    // Perfect Sanctuary 
    --h1:64px;
    --mobile-h1:48px;
    --h2:48px;
    --mobile-h2:32px;
    --tagline:20px;
    --mobile-tagline:18px;
    --h3:32px;
    --mobile-h3:24px;
    --h4:24px;
    --mobile-h4:20px;
    --h5: 20px;
    --mobile-h5:18px;
    --text:16px;
    --mobile-text:14px;
    --gold:#E3BB6E;
    --perfect-sanctuary-blue:#295362;
    --perfect-sanctuary-light-blue:#CEE4EC;
  }

  
  
  
  body{
    line-height: 1.5;
    width: 100%;
    font-family: 'Poppins';
    background: white;
    color: var(--white);
    overflow-x:hidden !important;
  }
  html {
    overflow-x:hidden;
  }
  h1 {
    font-family: 'Baskervville', serif;    
    font-weight:400;
    line-height:1;
    font-size:var(--h1);
    max-width:24ch;
    text-align:center;
    

    @media  (max-width:700px) {
      font-size:var(--mobile-h1) !important;
    }
  }


  h2 {
    font-size:var(--h2) !important;
        font-family: 'Baskervville', serif !important;    


    
    @media  (max-width:700px) {
      font-size:var(--mobile-h2) !important;
    }
  }


  h3 {
      font-size:var(--h3) !important;
      font-family: 'Baskervville', serif;    
      color:var(--black);



    @media  (max-width:700px) {
      font-size:var(--mobile-h3) !important;
    }
  }
  h4 {
      font-size:var(--h4) !important;
      font-family: 'Baskervville', serif; 
      color:var(--black);
   


    @media  (max-width:700px) {
      font-size:var(--mobile-h4) !important;
    }
  }
  h5 {
      font-size:var(--h5) !important;
      font-family: 'Baskervville', serif;    
      color:var(--black);


    @media  (max-width:700px) {
      font-size:var(--mobile-h5) !important;
    }
  }
  p:not(.tagline, .quote) {
    @media  (max-width:700px) {
      font-size:var(--mobile-text) !important;
    }
  }

  p.tagline {
        font-family: 'Baskervville', serif;    

  }

  a{
    text-decoration: none;
    cursor:pointer
  }
  li {
    cursor:pointer;
  }
  .container{
    max-width: 1440px;
    width: 100%;
    padding:6rem 4rem;

    @media  (max-width: 700px) {
      padding-inline:2rem;
    }
    @media  (max-width: 500px) {
      padding-inline:1rem;
    }
  }
  img{
    width: 100%;
  }
  li, ul {
    list-style: none;
  }
`;

export default GlobalStyles;
