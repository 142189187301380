import React from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import ParagragphText from '../typography/ParagrapghText';
import {
  ProjectsSearchResultItem,
  AuthorsSearchResultItem,
  ServicesSearchResultItem,
} from './SearchResultItem';

function SearchResult({
  searchQuery,
  projectsIndexStore,
  authorsIndexStore,
  servicesIndexStore,
  eventIndexStore,
}) {
  const projectsResult = useFlexSearch(
    searchQuery,
    JSON.stringify(projectsIndexStore.index),
    projectsIndexStore.store
  );

  const authorsResult = useFlexSearch(
    searchQuery,
    JSON.stringify(authorsIndexStore.index),
    authorsIndexStore.store
  );
  const servicesResult = useFlexSearch(
    searchQuery,
    JSON.stringify(servicesIndexStore.index),
    servicesIndexStore.store
  );
  const eventResult = useFlexSearch(
    searchQuery,
    JSON.stringify(eventIndexStore.index),
    eventIndexStore.store
  );

  if (
    projectsResult.length === 0 &&
    authorsResult.length === 0 &&
    servicesResult.length === 0 &&
    eventResult.length === 0
  ) {
    return <ParagragphText>No result found</ParagragphText>;
  }
  return (
    <>
      {projectsResult.length > 0 && (
        <>
          <ParagragphText
            style={{
              color: 'var(--perfect-sanctuary-light-blue)',
              fontSize: '2rem',
            }}
          >
            Projects
          </ParagragphText>
          {projectsResult.map((result) => (
            <ProjectsSearchResultItem key={result.id} project={result} />
          ))}
        </>
      )}

      {authorsResult.length > 0 && (
        <>
          <ParagragphText
            style={{
              color: 'var(--perfect-sanctuary-light-blue)',
              fontSize: '2rem',
            }}
          >
            Authors
          </ParagragphText>
          {authorsResult.map((result) => (
            <AuthorsSearchResultItem key={result.id} author={result} />
          ))}
        </>
      )}
      {servicesResult.length > 0 && (
        <>
          <ParagragphText
            style={{
              color: 'var(--perfect-sanctuary-light-blue)',
              fontSize: '2rem',
            }}
          >
            Services
          </ParagragphText>
          {servicesResult.map((result) => (
            <ServicesSearchResultItem key={result.id} services={result} />
          ))}
        </>
      )}
      {eventResult.length > 0 && (
        <>
          <ParagragphText
            style={{
              color: 'var(--perfect-sanctuary-light-blue)',
              fontSize: '2rem',
            }}
          >
            Events
          </ParagragphText>
          {eventResult.map((result) => (
            <ServicesSearchResultItem key={result.id} services={result} />
          ))}
        </>
      )}
    </>
  );
}

export default SearchResult;
