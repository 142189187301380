import styled from 'styled-components';

export const SearchFieldStyles = styled.label`
  position: relative;
  width: 100%;
  input {
    width: 100%;
    font-size: var(--text);
    padding: 1rem;
    border-radius: 0.25rem;
    color: var(--perfect-sanctuary-blue);
    font-family: Poppins;
    border: none;
    outline: none;
    &:focus {
      outline: 2px solid var(--perfect-sanctuary-blue);
    }
  }
  .searchIcon {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--perfect-sanctuary-blue);
  }
  @media (max-width: 768px) {
    input {
      font-size: 1.6rem;
      padding: 0.6rem;
      &:focus {
        outline: 2px solid var(--secondary);
      }
    }
  }
`;
