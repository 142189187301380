import styled from 'styled-components';

export const ParagraphTextStyles = styled.p`
  font-family: 'Poppins', sans-serif;
  color: var(--perfect-sanctuary-blue);
  font-size: var(--text);
  line-height: 1.6;
  font-weight: 300;
  display: inline;
  @media (max-width: 768px) {
    font-size: var(--mobile-text);
  }
`;
